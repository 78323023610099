<template>
  <div>
      <my-header/>
      <div class="table-box">
          <div>
              <span>所有班组</span>
               
          </div>
            <el-card class='box-card'>
                <div :style="{fontSize:'16px',color:'#888'}">
                    <span>班组查询:</span>
                    <el-select
                    @change="getsort"
                    :style="{ width: '150px', marginLeft: '20px' }"
                    v-model="typevalue"
                    placeholder="全部">
                        <el-option
                         v-for="item in options"
                        :key="item.value" 
                        :label="item.label" 
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <!-- 表结构 -->
                    <el-table class="Table"
                    stripe :data="sortList" border
                    :default-sort="{prop:'updateTime,groupNum',order:'descending'}">
                        <el-table-column
                        property="groupNum"
                        label="班组名称"
                        width="240"
                        fixed="left">
                        </el-table-column>
                        <el-table-column
                        property="realName"
                        label="班组长"
                        width="130"
                        ></el-table-column>
                        <el-table-column
                         property="updateTime"
                        label="更新时间"
                        width="180"
                        sortable>
                        </el-table-column>
                        <el-table-column
                        property="groupType" label="班组类型" width="120">
                        </el-table-column>
                        <el-table-column 
                            property="groupNum" 
                            label="班组人数"
                            width="120"
                            sortable>
                            </el-table-column>
                        <el-table-column
                        property="areaName"
                        label="业务所在地址"
                        width="300">
                        </el-table-column>
                        <el-table-column label="操作" width="150" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                            class='look'
                            type="primary"
                            size="small"
                            @click="lookdetails(scope.row)"
                            >查看详情</el-button
                            >
                        </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            
      </div>
  </div>
</template>

<script>
import MyHeader from "../components/my-header.vue";
export default {
    data(){
        return{
            //数据
            item:[],
            options:[
                {
                value:0,
                label:"全部"
                },
            ],
            sortList:[],
            typevalue:""
        }
    },
    methods:{
    getItem(){
            this.item=JSON.parse(this.$route.query.item)
            console.log(this.item,'数据')
        },
    lookdetails(v) {
      this.$router.push({
        path: "/groupDetail",
        query: {
          item: JSON.stringify(v),
        },
      });
    },
    //分类获取
    async getsort(){
        let typevalue=""
        if(this.typevalue==0 || this.typevalue==""){
            typevalue=""
        }else{ 
            typevalue=`&groupType=${this.typevalue}`
            }
     const res = await this.$http({
        methos: "get",
        url: `front/groupWork/findList?orderBy=update_time desc${typevalue}`
      });
      console.log(res.data.data.list,"分类");
      this.sortList=res.data.data.list;
    },
    //获取类型
    async getType(){
       const res = await this.$http({
        method: "GET",
        url: "front/workType/getTypeList?type=0",
      });
      let Type={}
      for(var i=0;i<res.data.data.length;i++){
        //   console.log(res.data.data[i].id)
            Type={
                value:res.data.data[i].name,
                label:res.data.data[i].name
            },
            this.options.push(Type)
      }
      console.log(this.options, "类型");     
    },
    },
    created(){
        this.getItem()
        this.getType()
        this.getsort()
    }

}
</script>

<style lang='scss' scoped>
.box-card {
  margin: 0 auto;
  margin-bottom: 50px;
  width: 80%;
  min-width: 800px;
  
}
.table-box {
  margin-top: 140px;
  font-family: "Arial";
 
  .table{
    width: 100%;
    margin: 10px auto;
    margin-bottom: 50px;
    
  }
  
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
  }
}
</style>